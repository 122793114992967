import React, { Component } from 'react';
import { Accordion, Sidebar, Menu, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import links from './links';
import Logo from '!svg-react-loader!../../images/logos/logo-debtconsolidation-white.svg?name=Logo';

import shortid from 'shortid';

export default class MegaMenuMobile extends Component {

    static propTypes = {
        menuVisible: PropTypes.bool,
        handleToggleMenu: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            mainIndex: -1,
            creditCardIndex: -1,
        };
        this.links = links.slice();
    }


    getMenu(section) {
        const { creditCardIndex } = this.state;
        return (
            <Accordion as={Menu} vertical className="submenu2">
                {section.subsections.map((item, index) => {
                    if (item.heading) {
                        const hasRel = item.hasOwnProperty("rel");
                        return (
                            <Menu.Item key={shortid.generate()}>
                                <Accordion.Title
                                    content={item.heading}
                                    index={index}
                                    active={creditCardIndex === index}
                                    onClick={this.handleCreditCardClick}
                                />
                                <Accordion.Content
                                    active={creditCardIndex === index}
                                    content={(
                                        <Menu vertical className="submenu3">
                                            {item.links.map(link => (
                                                <Menu.Item className="title" key={shortid.generate()}>
                                                    <a href={link.href} {...(hasRel ? { rel: item.rel } : {})}>{link.name}</a>
                                                </Menu.Item>
                                            ))}
                                        </Menu>
                                    )}
                                />
                            </Menu.Item>
                        );
                    }
                    return null;
                })}
            </Accordion>
        );
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const { mainIndex } = this.state;
        const newIndex = mainIndex === index ? -1 : index;

        this.setState({ mainIndex: newIndex });
    };

    handleCreditCardClick = (e, titleProps) => {
        const { index } = titleProps;
        const { creditCardIndex } = this.state;
        const newIndex = creditCardIndex === index ? -1 : index;

        this.setState({ creditCardIndex: newIndex });
    };


    render() {
        const { mainIndex } = this.state;
        const { menuVisible, handleToggleMenu } = this.props;

        return (
            <Sidebar as={Menu} animation="push" direction="right" icon="labeled" vertical visible={menuVisible}
                     width="wide">
                <Icon className="close" name="close" size="large" onClick={handleToggleMenu}/>

                <Accordion as={Menu} vertical>
                    <Menu.Item className="logo">
                        <a href="/"><Logo className="logo"/></a>
                    </Menu.Item>
                    {this.links.map((link, index) => {
                        return (
                            <Menu.Item className="submenu" key={shortid.generate()}>
                                <Accordion.Title
                                    content={link.heading}
                                    index={index}
                                    active={mainIndex === index}
                                    onClick={this.handleClick}
                                />
                                <Accordion.Content active={mainIndex === index} content={this.getMenu(link)}/>
                            </Menu.Item>
                        )
                    })}
                </Accordion>
            </Sidebar>
        );
    }
}

